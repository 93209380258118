import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  catchError,
  flatMap,
  map,
  share,
  switchMap,
  tap,
} from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  CastsService,
  Group,
  OAuthService,
  PassService,
  V2Cast,
  V2CastsService,
} from "../sinigangnababoywithgabi";

export interface DashBoardCard {
  icon: string;
  iconColor?: string;
  routerLink?: string;
  href?: string;
  target?: string;
  title: string;
  description: string;
  backgroundColor: string;
  textBackgroundColor?: string;
  disabled?: boolean;
  hide?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  private cachedCast: V2Cast;
  private cachedCastObservable: Observable<V2Cast>;
  private cachedUserInfo: {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  private cachedCards: DashBoardCard[];

  constructor(
    private castService: CastsService,
    private v2CastService: V2CastsService,
    private oauthService: OAuthService,
    private passService: PassService
  ) {}

  getUserInfo() {
    if (this.cachedUserInfo) {
      // Return cache is it exists
      return of({ ...this.cachedUserInfo });
    } else {
      //
      return this.oauthService.getUserInfo().pipe(
        tap((result: any) => {
          this.cachedUserInfo = { ...result };
        })
      );
    }
  }

  getPermissions(): Observable<string[]> {
    let permissions = JSON.parse(localStorage.getItem("permissions"));

    return of(permissions);
  }

  getCast() {
    if (!this.cachedCast && this.cachedCastObservable) {
      return this.cachedCastObservable;
    } else if (this.cachedCast) {
      // Return cache if it exists
      return of({ ...this.cachedCast });
    } else {
      let host;

      if (
        /.+staging\.dashboard\.buri\.dev$/.test(window.location.host) ||
        window.location.hostname === "bokchoy.dashboard.buri.dev"
      ) {
        host = `${window.location.host.split(".")[0]}.castlms.com`;
      } else if (
        /.+\.castdashboard\.com$/.test(window.location.host) ||
        /.+\.dashboard\.buri\.io$/.test(window.location.host)
      ) {
        host = `${window.location.host.split(".")[0]}.castlms.com`;
      } else if (
        /.+preprod\.dashboard\.buri\.dev$/.test(window.location.host)
      ) {
        host = `${
          window.location.host.split(".")[0]
        }.preprod.cast-learner-react.buri.dev`; // Pinalitan ulit implementation sa cast
        //assign defaultDomain.learner as host
      } else if (/.+dashboard\.buribooks\.com$/.test(window.location.host)) {
        host = `${window.location.host.split(".")[0]}.buribooks.com`;
      } else {
        host = window.location.host;
      }

      // Return cast of particular domain
      this.cachedCastObservable = this.v2CastService.domainGet(host).pipe(
        map((result) => {
          return result.data.attributes.castId;
        }),
        catchError((err) => {
          // Fallback legacy domains and for localhost cast
          return of(
            environment.DOMAIN_CAST_MAP.get(window.location.origin) + ""
          );
        }),
        switchMap((castId) => {
          // Fetch the actual cast
          return this.v2CastService.castGet(castId);
        }),
        tap((cast) => {
          // Cache the cast
          this.cachedCast = { ...cast };
        }),
        share()
      );

      return this.cachedCastObservable;
    }
  }

  saveCast(cast: V2Cast) {
    // Saves the cast via v1 code
    this.cachedCast.data.attributes = {
      ...this.cachedCast.data.attributes,
      ...cast.data.attributes,
    };
    return this.castService.castUpdate(this.cachedCast.data.id + "", {
      cast: {
        title: cast.data.attributes.title,
        properties: {
          ...this.cachedCast.data.attributes,
          castIcon: cast.data.attributes.castIcon,
        },
      },
    });
  }

  // Legacy code
  getDashboardNameAndLogo() {
    return this.getCast().pipe(
      map((result) => {
        return {
          dashboardLogoUrl: result.data.attributes.castIcon?.web?.nav,
          dashboardTitle: result.data.attributes.title,
        };
      })
    );
  }

  // Dasboard cards for homepage use and navbar use
  getDashboardCards(): Observable<DashBoardCard[]> {
    if (this.cachedCards) {
      return of(this.cachedCards);
    } else {
      return this.oauthService.getUserPermissions().pipe(
        map((result) => {
          let permissions = result.permissions;

          let allowUserManagement = false;
          let allowResourceManagement = false;
          let allowCastManagement = true;
          let allowCourseManagement = true;
          let allowPurchaseManagement = environment.PURCHASES_ENABLED;
          let allowMailerManagement = environment.MAILER_ENABLED;
          let allowIDIS = environment.IDIS_ENABLED;
          let allowTIS = environment.TIS_ENABLED;
          permissions.forEach((permission) => {
            if (
              /create:collections/.test(permission) ||
              /create:resources/.test(permission) ||
              /create:application:.*/.test(permission) ||
              /update:application:.*/.test(permission) ||
              /create:package:.*/.test(permission) ||
              /update:package:.*/.test(permission)
            ) {
              allowResourceManagement = true;
            }

            if (
              /read:users:.*/.test(permission) ||
              /update:users:.*/.test(permission) ||
              /read:groups:.*/.test(permission) ||
              /update:groups:.*/.test(permission)
            ) {
              allowUserManagement = true;
            }
          });

          let cards: DashBoardCard[] = [];

          cards.push({
            icon: "palette",
            href: "/casteditor",
            target: "_self",
            title: "Cast Editor",
            description: "Edit how your Cast is displayed to users",
            backgroundColor: "#752B8F",
            // textBackgroundColor: "#cbcbcb",
            // disabled: true,
          });

          if (allowUserManagement) {
            cards.push({
              icon: "group",
              href: "/users-and-groups",
              target: "_self",
              title: "Users and Groups",
              description: "Manage Users and group accounts",
              backgroundColor: "#983fc1",
            });

            if (allowResourceManagement) {
              cards.push({
                icon: "view_module",
                routerLink: "/components/list",
                title: "Resource Library",
                description: "Upload and Manage Resources",
                backgroundColor: "#F2994A",
              });
            }
          }

          // cards.push({
          //   icon: "collections_bookmark",
          //   routerLink: "/tis",
          //   title: "TIS",
          //   description: "Training Information System",
          //   backgroundColor: "#64C",
          // });
          // cards.push({
          //   icon: "bar_chart",
          //   routerLink: "/reporting/list",
          //   title: "Reports",
          //   description: "View reports and summaries",
          //   backgroundColor: "#64C238",
          // });
          if (allowIDIS) {
            cards.push({
              icon: "collections_bookmark",
              routerLink: "/idis/users/settings",
              title: "IDIS",
              description: "Individual Development Information System",
              backgroundColor: "#808080",
            });
          }

          if (allowCourseManagement) {
            cards.push({
              icon: "collections_bookmark",
              href: "/courses",
              title: "Courses",
              target: "_self",
              description: "Create and Monitor Courses",
              backgroundColor: "#4894da",
            });
          }

          if (allowTIS) {
            cards.push({
              icon: "local_library",
              href: "/tis",
              title: "TIS",
              target: "_self",
              description: "Training Information System",
              backgroundColor: "#236AA3",
            });
          }

          if (allowPurchaseManagement) {
            cards.push({
              icon: "local_mall",
              routerLink: "/purchases/list",
              title: "Purchases",
              description: "Configure and manage purchases",
              backgroundColor: "#219653",
            });
          }
          if (allowMailerManagement) {
            cards.push({
              icon: "email",
              routerLink: "/_mailer",
              title: "Mailer",
              description: "Send email to user(s) and Configure system emails",
              backgroundColor: "#78A1BB",
            });
          }
          cards.push({
            icon: "help",
            href: "https://support.castlms.com/",
            target: "_blank",
            title: "Customer Support",
            description: "View user manual and documentation",
            backgroundColor: "#bb6bd9",
          });

          cards.push({
            icon: "settings",
            href: "/general-settings",
            target: "_self",
            title: "General Settings",
            description: "Configure default Cast settings",
            backgroundColor: "#FFC515",
          });

          return cards;
          // {
          //   icon: "palette",
          //   routerLink: "/cast/profile",
          //   title: "Cast Editor",
          //   description: "Edit how your Cast is displayed to users",
          //   backgroundColor: "#630865",
          //   disabled: true,
          //   hide: !allowCastManagement,
          // },,,
          // {
          //   icon: "clipboard",
          //   iconColor: "#cbcbcb",
          //   // routerLink: "/users",
          //   title: "Activities and Exams",
          //   description: "Coming Soon",
          //   backgroundColor: "#7a7a7a",
          //   textBackgroundColor: "#cbcbcb",
          //   disabled: true,
          // },
          // {
          //   icon: "insert_chart",
          //   iconColor: "#cbcbcb",
          //   // routerLink: "/users",
          //   title: "Analytics",
          //   description: "Coming Soon",
          //   backgroundColor: "#7a7a7a",
          //   textBackgroundColor: "#cbcbcb",
          //   disabled: true,
          // },
        }),
        tap((result) => {
          this.cachedCards = result;
        })
      );
    }
  }

  getDefaultGroup() {
    return this.getCast().pipe(
      flatMap((cast) => {
        return this.passService.getGroup(cast.data.attributes.defaultGroupId);
      })
    );
  }

  getInactiveGroup(): Observable<Group> {
    return this.getCast().pipe(
      flatMap((cast) => {
        if (!cast.data.attributes.deactivatedUsersGroupId) {
          of(null);
        } else {
          return this.passService.getGroup(
            cast.data.attributes.deactivatedUsersGroupId
          );
        }
      })
    );
  }

  createInactiveGroup(): Observable<Group> {
    return this.getCast().pipe(
      flatMap((cast) => {
        return this.passService
          .createGroup({
            group: {
              name: `${cast.data.attributes.title} Inactive Users`,
              parentId: cast.data.attributes.defaultGroupId,
            },
          })
          .pipe(
            flatMap((group) => {
              cast.data.attributes.deactivatedUsersGroupId = group["id"];

              return this.saveCast(cast).pipe(map(() => group));
            })
          );
      })
    );
  }

  getIDISActions() {
    return [
      {
        icon: "group",
        name: "IDIS List of users",
        description: "View list of users in IDIS",
        routerLink: "/idis/users",
        disabled: false,
      },
      {
        icon: "bar_chart",
        name: "Analytics",
        description: "View analytics of IDIS users",
        routerLink: "/idis/analytics",
        disabled: false,
      },
      {
        icon: "settings",
        name: "Settings",
        description: "Set up IDIS",
        routerLink: "/idis/settings",
        disabled: true,
      },
    ];
  }

  getTISActions() {}

  getEmailActions() {
    //Replace routerLink to appropriate routes
    let actions = [];
    actions.push({
      icon: "mood",
      name: "Welcome/Confirmation Email",
      description: "Sent after adding new user or after a user signs up",
      routerLink: "/",
    });
    actions.push({
      icon: "drafts",
      name: "Invite to Cast",
      description: "Sends invite to a user from another Cast",
      routerLink: "/",
    });
    actions.push({
      icon: "refresh",
      name: "Reset Password",
      description: "Sent when Forgot Password is triggered",
      routerLink: "/",
    });
    actions.push({
      icon: "school",
      name: "Certificate",
      description: "Sent when a user completed a Course with Certification",
      routerLink: "/",
    });

    return actions;
  }
}
